import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { url } from '../config/config';
import BlogTable from '../components/BlogTable';
import { CirclePlus, Search, UserPlus } from 'lucide-react';
import BlogPopup from '../components/BlogPopup';
import Pagination from '../components/Pagination';

function Blog() {

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPupup,setShowPupup] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  async function Blogs() {

    const responses = await axios.get(`${url.getallblogs}?page=${page}&limit=${10}&search=${query}`);
    if (responses.data.success) {
      setBlogs(responses.data.data);
      setTotalPages(responses.data.pages)
      setLoading(false);
    }
  }

  useEffect(() => {
    Blogs()
  }, [loading,page,query])


  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-200"></div>
      </div>
    )
  }

  return (
    <div className='custom-container space-y-5'>
      <div className="flex justify-between items-center">
        <div className="w-96 flex items-center bg-transparent border border-zinc-800 p-1 rounded-md">
          <div className="pl-2">
            <Search className='text-white' size={17}/>
          </div>
          <input 
            type="text" 
            onChange={(e)=>setQuery(e.target.value)} 
            placeholder='search' className="text-white placeholder:text-white text-base  pl-2 form-control bg-transparent w-full border-none outline-none " 
    
            />
        </div>
        <button
          className="btn flex items-center text-sm border-2 text-wallstreet-white border-zinc-600 hover:text-wallstreet-foreground hover:border-wallstreet-primary gap-2"
         onClick={()=>setShowPupup(true)}
        >
          <CirclePlus />  Create Blog
        </button>
      </div>

      <BlogTable blogs={blogs}  setLoading={setLoading}/>

      <Pagination totalPages={totalPages} setPage={setPage} page={page}/>

      {showPupup && <BlogPopup type="new" sethidepopup={setShowPupup} setLoading={setLoading}/>}
    </div>
  )
}

export default Blog