

export const mainurl = 'https://wallstreet-admin-backend-code.vercel.app'
// export const mainurl = 'http://localhost:8080'
export const url = {
    getallEntrolls: `${mainurl}/api/entrolls/all-entrolls`,
    createNewEntroll: `${mainurl}/api/entrolls/create-entroll`,
    updateEntroll: `${mainurl}/api/entrolls/update-entroll`,
    deleteEntroll: `${mainurl}/api/entrolls/delete-entroll`,
    getallblogs: `${mainurl}/api/blog/get-allblog`,
    getblog: `${mainurl}/api/blog/get-blogdetails`,
    createNewBlog: `${mainurl}/api/blog/create-blog`,
    updateBlog: `${mainurl}/api/blog/update-blog`,
    deleteBlog: `${mainurl}/api/blog/delete-blog`,
    entrollcount: `${mainurl}/api/entrolls/getcount`,
    login: `${mainurl}/api/user/login`,
    forgotpassword: `${mainurl}/api/user/forgotpassword`,
    userprofile: `${mainurl}/api/user/getprofile`
}