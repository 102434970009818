import React from 'react'
import { assets } from './images/assets'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'


function Header() {

    const navigate = useNavigate();
    const location = useLocation();
    const login = localStorage.getItem("registertoken");

    const handleLogout = () => {
        localStorage.removeItem("registertoken");
        navigate('/login')
    }

    return (
        <div className="h-28 overflow-auto">
            {login && <div className="h-28 flex top-0  justify-between fixed w-full z-40 custom-container bg-wallstreet-black">
                <Link to={"/"} className="flex items-center gap-4">
                    <img
                        src={assets.LOGO_1}
                        alt="logo"
                        height={50}
                        width={50}
                    />
                    <h1 className="text-sm lg:text-xl font-bold text-wallstreet-white">
                        WallStreet Verse
                    </h1>
                </Link>
                <div className="hidden lg:flex lg:items-center">
                    <div className="text-wallstreet-white text-sm font-medium flex items-center gap-10">
                        <>
                            <Link to={"/"} className={location.pathname === "/" ? "text-wallstreet-foreground underline underline-offset-8" : ""}>Home</Link>
                            <Link to={"/enroll"} className={location.pathname === "/enroll" ? "text-wallstreet-foreground underline underline-offset-8" : ""}>Enrolls</Link>
                            <Link to={"/blog"} className={location.pathname === "/blog" ? "text-wallstreet-foreground underline underline-offset-8" : ""}>Blogs</Link>
                            <Link to={"/profile"} className={location.pathname === "/profile" ? "text-wallstreet-foreground underline underline-offset-8" : ""}>Profile</Link>
                            <button
                                className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 w-fit text-center"
                                onClick={handleLogout}
                            >
                                Log Out
                            </button>
                        </>

                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Header