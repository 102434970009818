import React from 'react'

function Footer() {
    return (
        <div className='flex justify-center items-end'>
            <p className="text-center align-bottom text-wallstreet-white text-sm text-opacity-50">
                <span className="text-wallstreet-foreground">
                    &copy; Copyrights reserved by wallstreetverse I {" "}
                </span>
                Designed by Marksman Studios
            </p>
        </div>
    )
}

export default Footer