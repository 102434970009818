import React, { useEffect, useState } from 'react'
import EntrollPupup from '../components/EntrollPupup'
import { Search, UserPlus } from 'lucide-react'
import EntrollTable from '../components/EntrollTable'
import axios from 'axios'
import { url } from '../config/config'
import Pagination from '../components/Pagination'

function Entroll() {

  const [entrolls, setEntrolls] = useState([]);
  const [showPupup, setShowPupup] = useState(false);
  const [loading, setloading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  const getAllEntrolls = async () => {

    const response = await axios.get(`${url.getallEntrolls}?page=${page}&limit=${10}&search=${query}`);

    if (response.data.success) {
      setEntrolls(response.data.data);
      setTotalPages(response.data.pages)
      setloading(false)
    }

  }

  useEffect(() => {
    getAllEntrolls();
  }, [loading,page,query])


  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-200"></div>
      </div>
    )
  }

  return (
    <div className='custom-container space-y-5'>

      <div className="flex justify-between items-center">
        <div className="w-96 flex items-center bg-transparent border border-zinc-800 p-1 rounded-md">
          <div className="pl-2">
            <Search className='text-white' size={17} />
          </div>
          <input type="text" placeholder='search' onChange={(e) => setQuery(e.target.value)}  className='text-white placeholder:text-white text-base focus:border-none focus:outline-none pl-2 form-control bg-transparent w-full border-none outline-none' />
        </div>
        <button
          className="btn flex items-center text-sm border-2 text-wallstreet-white border-zinc-600 hover:text-wallstreet-foreground hover:border-wallstreet-primary gap-2"
          onClick={() => setShowPupup(true)}
        >
          <UserPlus />  Create New
        </button>
      </div>

      {entrolls.length > 0 && <EntrollTable setloading={setloading} data={entrolls} />}

      <Pagination totalPages={totalPages} setPage={setPage} page={page}/>

      {showPupup && <EntrollPupup type={"new"} setloading={setloading} sethidepopup={setShowPupup} />}
    </div>
  )
}

export default Entroll