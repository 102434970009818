import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App bg-wallstreet-black flex flex-col justify-center min-h-screen">
      <ToastContainer />
      <Header />
      <main className='min-h-[calc(100vh-150px)]'>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
