import axios from 'axios';
import React, { useState } from 'react'
import { url } from '../config/config';
import { toast } from 'react-toastify';

function EntrollPupup({ data, sethidepopup, setloading, type }) {

    const [name, setName] = useState(data ? data.name : "");
    const [email, setEmail] = useState(data ? data.email : "");
    const [mode, setMode] = useState(data ? data.mode : "Offline");
    const [thoughts, setThoughts] = useState(data ? data.thoughts : "");
    const [phone, setPhone] = useState(data ? data?.phone : "");
    const [id, setId] = useState(data ? data._id : "");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (phone.trim().length !== 10) {
            toast.error("Invalid phone number");
            return;
        }
        

        const data = {
            name: name,
            email: email,
            mode: mode,
            thoughts: thoughts,
            id: id,
            phone
        }

        let entrollurl = type === "update" ? url.updateEntroll : url.createNewEntroll

        const response = await axios.post(entrollurl, data);

        if (response.data.success) {
            toast.success(response.data.message);
            setloading(true);
            sethidepopup(false);
        }
        else {
            toast.error(response.data.message)
        }

    }



    return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>

            <div className=" w-[500px]" >
                <div className="modal-dialog modal-dialog-centered ">
                    <form onSubmit={handleSubmit} className="modal-content transition-all duration-200 bg-wallstreet-black space-y-3 p-3 rounded-md shadow-xl">
                        <div className="modal-header border-none">
                            <h1 className="modal-title fs-5 text-wallstreet-white" id="exampleModalLabel">{data ? "Edit Enroll" : "Create Enroll"}</h1>
                        </div>
                        <div className="modal-body space-y-3">
                            <input type="text" className="form-control py-2 bg-transparent border-zinc-600 text-white placeholder:text-white" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} name='name' required />
                            <input type="email" className="form-control py-2 bg-transparent border-zinc-600 text-white placeholder:text-white" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} name='email' required />
                            <input type="number" className="form-control py-2 bg-transparent border-zinc-600 text-white placeholder:text-white" placeholder="Phone Number" minLength={10} value={phone} onChange={(e) => setPhone(e.target.value)} name='name' required />
                            {/* <select onChange={(e) => setMode(e.target.value)} required value={mode} className="form-control py-2 bg-transparent border-zinc-600 text-white placeholder:text-white">
                                <option value="">select option</option>
                                <option value="Offline">Offline</option>
                                <option value="Online">Online</option>
                            </select> */}

                            <textarea placeholder='Enter Your Description' value={thoughts} onChange={(e) => setThoughts(e.target.value)} className='w-full border p-2 bg-transparent text-white border-zinc-600 rounded-md'></textarea>
                        </div>
                        <div className="modal-footer space-x-4 border-zinc-700">
                            <button type="button" onClick={() => sethidepopup(false)} className="btn bg-wallstreet-white hover:bg-wallstreet-primary hover:text-white" >Close</button>
                            <button type="submit" className="btn bg-wallstreet-black text-white border-2 border-zinc-700 hover:border-wallstreet-primary hover:text-wallstreet-foreground">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EntrollPupup