
import React from 'react'

function Pagination({ totalPages, setPage, page }) {
    return (
        <div className='flex justify-center items-center gap-3'>
            {Array.from({ length: totalPages }, (_, i) => (
                <button
                    key={i}
                    onClick={() => setPage(i + 1)}
                    disabled={i + 1 === page}
                    className={`px-2 ${page == i+1 && 'border-wallstreet-primary'} text-wallstreet-white text-lg border-2 cursor-pointer border-zinc-800 hover:border-wallstreet-primary rounded-md`}
                >
                    {i + 1}
                </button>
            ))}
        </div>
    )
}

export default Pagination