
import App from "./App";

import { createBrowserRouter } from "react-router-dom";
import Entroll from "./page/Entroll";
import Blog from "./page/Blog";
import Home from "./page/Home";
import Login from "./page/Login";
import ForgotPassword from "./page/ForgotPassword";
import Profile from "./page/Profile";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "enroll",
                element: <Entroll/>,
            },
            {
                path: "blog",
                element: <Blog/>,
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "forgotpassword",
                element: <ForgotPassword/>,
            },
            {
                path: "profile",
                element: <Profile/>
            }
        ]
    }
])