import React, { useState } from 'react'
import { assets } from '../components/images/assets'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../config/config';
import { toast } from 'react-toastify';

function ForgotPassword() {

    const [username,setUserName] = useState('');
    const [password,setPassword] = useState('');
    const [loading,setLoading] = useState(false);

    const navigate = useNavigate();

    const handleForgotPassord = async (e) => {
        e.preventDefault();

        const data = {
            username: username,
            password: password
        }
        setLoading(true)
        const response = await axios.post(url.forgotpassword,data)
        if (response.data.success) {
            setLoading(false);
            toast.success(response.data.message);
            navigate('/login');
        }
        else {
            toast.error(response.data.message);
            setLoading(false);
        }
    }

    return (
        <div className='custom-container flex flex-col justify-center items-center space-y-5 h-[calc(100vh-150px)]'>
            <form className="w-[450px] flex flex-col justify-center " onSubmit={handleForgotPassord}>
                <div className="flex justify-center items-center">
                    <img src={assets.LOGO_1} alt={assets.LOGO_1} width={70}/>
                </div>
                <div className="mb-2">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your username</label>
                    <input type="text" id="email" value={username} onChange={(e)=>setUserName(e.target.value)}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@gmail.com" required />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New password</label>
                    <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>
                {!loading && <button type="submit" className="text-white mt-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>}
                {loading && <button className='flex justify-center items-center'>
                    <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-wallstreet-primary"></div>
                </button>}
            </form>

        </div>
    )
}

export default ForgotPassword