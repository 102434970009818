

import { Pencil, Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import moment from 'moment'
import BlogPopup from './BlogPopup';
import axios from 'axios';
import { url } from '../config/config';
import { toast } from 'react-toastify';

function BlogTable({blogs,setLoading}) {


    const [showPupup,setShowPupup] = useState(false);
    const [blog,setBlog] = useState({})

    const updateBlog = (Blog) => {
        setShowPupup(true);
        setBlog(Blog);
    }

    const deleteBlog = async (id) => {

        const response = await axios.post(`${url.deleteBlog}/${id}`);
        console.log(response);
        if (response.data.success) {
            setLoading(true);
            toast.success(response.data.message);
        }
        else {
            toast.error(response.data.message);
        }
    }

    return (
        <div>
            <table className="table table-dark table-hover w-full ">
                <thead>
                    <tr>
                        <th colSpan={2}>#</th>
                        <th colSpan={2}>Image</th>
                        <th colSpan={2}>Title</th>
                        <th colSpan={2}>Date</th>
                        <th colSpan={2}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        blogs.map((item,index) => {
                            return (<tr key={index}>
                                <td colSpan={2}>{index+1}</td>
                                <td colSpan={2} className='w-10 h-10'>
                                    <img src={item.image} alt={item.title} className='w-10 h-10'/>
                                </td>
                                <td colSpan={2}>{item.title}</td>
                                <td colSpan={2}>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                                <td colSpan={2} className='flex gap-3 h-14'>
                                    <button
                                        className='flex items-center gap-2 border-2 text-sm text-wallstreet-foreground border-wallstreet-foreground hover:border-wallstreet-primary hover:text-wallstreet-white transition-all duration-200 px-3  rounded-md'
                                        onClick={() => updateBlog(item)}
                                    >
                                        <Pencil size={15} /> Edit
                                    </button>
                                    <button
                                        className='flex items-center gap-2 bg-red-800 px-3  text-sm rounded-md hover:bg-wallstreet-primary hover:text-wallstreet-foreground transition-all duration-200'
                                        onClick={()=> deleteBlog(item._id)}
                                    >
                                        <Trash2 size={15} /> Delete
                                    </button>
                                </td>
                            </tr>)
                        })
                    }

                </tbody>
            </table>

            {showPupup && <BlogPopup data={blog} type="update" sethidepopup={setShowPupup} setLoading={setLoading}/>}
        </div>
    )
}

export default BlogTable