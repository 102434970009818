import { Pencil, Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import EntrollPupup from './EntrollPupup'
import axios from 'axios';
import { url } from '../config/config';
import { toast } from 'react-toastify';

function EntrollTable({ data,setloading }) {


    const [showPupup,setShowPupup] = useState(false);
    const [user,setUser] = useState({})

    const editUser = (details) => {
        setUser(details)
        setShowPupup(true);
    }

    const deleteEntroll = async (id) => {
        const response = await axios.post(`${url.deleteEntroll}/${id}`);
        if (response.data.success) {
            setloading(true);
            toast.success(response.data.message);
        }
        else {
            toast.error(response.data.message);
        }
    }

    return (
        <div>
            <table className="table table-dark table-hover w-full ">
                <thead>
                    <tr>
                        <th colSpan={2}>#</th>
                        <th colSpan={2}>Name</th>
                        <th colSpan={2}>Email</th>
                        <th colSpan={2}>Mode</th>
                        <th colSpan={2}>Phone</th>
                        <th colSpan={2}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td colSpan={2}><h1>{index + 1}</h1></td>
                                    <td colSpan={2}><h1>{item.name}</h1></td>
                                    <td colSpan={2}><h1>{item.email}</h1></td>
                                    <td colSpan={2} ><h1 className={`${item.mode === "Offline" ? "text-wallstreet-primary" : "text-wallstreet-foreground"}`}>{item.mode}</h1></td>
                                    <td colSpan={2}><h1>{item.phone}</h1></td>
                                    <td colSpan={2} className='flex gap-3 h-14'>
                                        <button 
                                            className='flex items-center gap-2 border-2 text-sm text-wallstreet-foreground border-wallstreet-foreground hover:border-wallstreet-primary hover:text-wallstreet-white transition-all duration-200 px-3  rounded-md'
                                            onClick={()=>editUser(item)}>
                                            <Pencil size={15} /> Edit
                                        </button>
                                        <button 
                                            className='flex items-center gap-2 bg-red-800 px-3  text-sm rounded-md hover:bg-wallstreet-primary hover:text-wallstreet-foreground transition-all duration-200'
                                            onClick={()=>deleteEntroll(item._id)}
                                        >
                                            <Trash2 size={15} /> Delete
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {showPupup && <EntrollPupup type={"update"} data={user} setloading={setloading} sethidepopup={setShowPupup}/>}
        </div>
    )
}

export default EntrollTable