

import axios from 'axios';
import React, { useState } from 'react'
import { url } from '../config/config';
import { toast } from 'react-toastify';

function BlogPopup({ data, sethidepopup,setLoading,type }) {

    const [title, setTitle] = useState(data ? data.title : "");
    const [image, setImage] = useState(data ? data.image : "https://img.icons8.com/color/96/add-image.png");
    const [desc, setDesc] = useState(data ? data.description : "");
    const [id, setId] = useState(data ? data._id : "")

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const data = {
            title: title,
            image: image,
            description: desc,
            id: id
        }

        let blogurl = type === "update" ? url.updateBlog : url.createNewBlog

        const response = await axios.post(blogurl, data);
        if (response.data.success) {
            sethidepopup(false);
            setLoading(true);
            toast.success(response.data.message)
        }
        else {
            toast.error(response.data.message)
        }
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };



    return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>

            <div className=" w-[500px]" >
                <div className="modal-dialog modal-dialog-centered ">
                    <form onSubmit={handleSubmit} className="modal-content transition-all duration-200 bg-wallstreet-black space-y-3 p-3 rounded-md shadow-xl">
                        <div className="modal-header border-none">
                            <h1 className="modal-title fs-5 text-wallstreet-white" id="exampleModalLabel">{data ? "Edit Blog" : "Create Blog"}</h1>
                        </div>
                        <div className="modal-body space-y-3">
                            <div className="">
                                <label htmlFor="image">
                                    <img src={image} alt="" className="w-full h-40 rounded-md" />
                                </label>
                                <input id='image' type="file" className="form-control py-2 bg-transparent border-zinc-600 text-white placeholder:text-white" placeholder="Image URL" onChange={handleImageUpload} hidden name='image' />
                            </div>
                            <input type="text" className="form-control py-2 bg-transparent border-zinc-600 text-white placeholder:text-white" placeholder="Name" value={title} onChange={(e) => setTitle(e.target.value)} name='name' required />
                            <textarea required placeholder='Enter Your Description' value={desc} onChange={(e) => setDesc(e.target.value)} className='w-full border p-2 bg-transparent text-white border-zinc-600 rounded-md'></textarea>
                        </div>
                        <div className="modal-footer space-x-4 border-zinc-700">
                            <button type="button" onClick={() => sethidepopup(false)} className="btn bg-wallstreet-white hover:bg-wallstreet-primary hover:text-white" >Close</button>
                            <button type="submit" className="btn bg-wallstreet-black text-white border-2 border-zinc-700 hover:border-wallstreet-primary hover:text-wallstreet-foreground">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BlogPopup