import { Album, Radio, Snowflake, SquareUser } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { assets } from '../components/images/assets'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { url } from '../config/config';

function Home() {

  const [entrolls, setEntrolls] = useState(0);
  const [blogs, setBlogs] = useState(0);
  const [modeCount,setModeCount] = useState({})
  const navigate = useNavigate();

  const getcounts = async () => {
    const respose = await axios.get(url.entrollcount); 

    if (respose.data.success) {
      setEntrolls(respose.data.data.totalCount);
      setModeCount(respose.data.data.modeCounts);
      setBlogs(respose.data.data2?.totalCount[0]?.count)
    }
  }

  useEffect(() => {

    const login = localStorage.getItem("registertoken");

    if (!login) {
      navigate('/login')
    }
    else {
      getcounts()
    }




  },[])

  return (
    <div className='custom-container space-y-5 h-[calc(100vh-150px)]'>
      <div className="flex items-center flex-col justify-center h-full gap-8">
        <div className="">
          <img src={assets.LOGO_1} alt={assets.LOGO_1} width={100} />
        </div>
        <div className="flex items-center gap-3 justify-center">
          <div className="relative min-w-96 h-48 hover:border-wallstreet-primary transition-all duration-200 flex flex-col items-center justify-center gap-2 border-2 border-zinc-800 shadow-xl rounded-md">
            <SquareUser size={30} className='text-wallstreet-primary' />
            <div className="">
              <h1 className='text-3xl text-wallstreet-white text-center'>{entrolls}</h1>
              <h1 className='flex gap-2 items-center text-3xl font-bold text-wallstreet-white text-center'>Enrolls</h1>
            </div>
            <div className="absolute top-3 left-3">
              <div className="flex items-center gap-2">
                <Radio className='text-wallstreet-primary' />
                <h1 className='text-white'>{modeCount.Online}</h1>
              </div>
            </div>
            <div className="absolute top-3 right-3">
              <div className="flex items-center gap-2">
                <Snowflake className='text-wallstreet-primary' />
                <h1 className='text-white'>{modeCount.Offline}</h1>
              </div>
            </div>
          </div>
          <div className="min-w-96 h-48 hover:border-wallstreet-primary transition-all duration-200 flex flex-col items-center justify-center gap-2 border-2 border-zinc-800 shadow-xl rounded-md">
            <Album size={30} className='text-wallstreet-primary' />
            <div className="">
              <h1 className='text-3xl text-wallstreet-white text-center'>{blogs}</h1>
              <h1 className='flex gap-2 items-center text-3xl text-wallstreet-white font-bold text-center'>Blogs</h1>
            </div>
          </div>
        </div>
        <div className="">

          <Link to={'enroll'} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              View Enrolls
            </span>
          </Link>

          <Link to={'blog'} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              View Blogs
            </span>
          </Link>

        </div>
      </div>
    </div>
  )
}

export default Home